<template>
    <div id="explore-page">
        <div id="explore-spinner" v-show="!pageLoaded">
            <Spinner/>
        </div>
        <div id="explore-page-content" v-show="pageLoaded">
            <div id="explore-header-container">
                <img id="explore-header-sml-scrn" @load="onImgLoad" src="@/assets/alt-banner-mobile.jpg" alt="Leo Tolstoy quoted over a bull">
                <img id="explore-header-lrg-scrn" @load="onImgLoad" src="@/assets/alt-banner-desktop.jpg" alt="Leo Tolstoy quoted over a bull">
            </div>
            <MissionStatement id="mission-statement" />
            <div id="our-story-container">
                <div id="story-title-container">
                    <ExplorePageTitles id="story-title" class="section-title" title="Our Story" />
                </div>
                <div id="image-container">
                    <img id="henry-quote-image" src="@/assets/chicken-text.jpg" alt="Henry Beston Quote">
                    <p id="henry-image-attribute">Photo taken at Lilydale in Calgary, AB</p>
                </div>
                <div id="story-paragraphs">
                    <p class="story-paragraph">
                        Calgary Animal Save (CAS), a chapter of the Animal Save Movement, was formed in 2017 by a group of Calgarians who 
                        celebrate the beauty and complexity of animals and support their protection. CAS operates under a set of shared 
                        principles and acknowledges the right of all beings - human and animal - to be free from harm despite and separate 
                        from the interests of more privileged and dominant groups.
                    </p>
                    <p class="story-paragraph">
                        <a class="anchor-color" target="_blank" href="https://www.qp.alberta.ca/documents/Acts/A41.pdf">Alberta's Animal Protection Act</a> is regularly 
                        cited as an adequate welfare mechanism for animals in the province. 
                        The act however, exempts most animals who are used for human purposes from protections beyond their basic needs for 
                        food, water, shelter, and care when wounded or ill. <a class="anchor-color" target="_blank" href="http://fcmconference.org/img/CambridgeDeclarationOnConsciousness.pdf">Evidence</a> 
                        shows that animals are more than biological machines so 
                        the act falls short. We must do more to protect animals as whole beings.
                    </p>
                    <p class="story-paragraph">
                        Calgarians face many serious problems, all of which deserve attention. Insufficient concern for and poor oversight of 
                        animal issues is one of them. As such CAS educates policymakers and the public about animal issues and promotes embedding 
                        adequate animal protections into policy and legislation.
                    </p>
                </div>
            </div>
            <div id="mobile-gallery-one">
                <v-lazy
                    v-model="lazyLinksMobGalOne"
                    :options="{
                    threshold: 1
                    }"
                    min-height="200"
                    height="fit-content"
                    transition="scale-transition"
                    class="mob-gal"
                    >
                    <MobileGallery :items="galleryTwo"/>
                </v-lazy>
            </div>
            <div id="shared-principles-container">
                <ExplorePageTitles class="section-title" title="Shared Principles" />
                <div id="shared-one">
                    <h2>I. Serving animal interests</h2>
                    <p>
                        We can be in service to animals by not only being present and strategic, but by holding ourselves to a higher standard in how we 
                        operate in the world. Keeping the following principles in mind helps us to maintain that high standard:
                    </p>
                    <p>
                        <ol>
                            <li>
                                <h4>Learning and Collective Intelligence</h4>
                                <p>
                                    We acknowledge that we hold a stronger potential for success through togetherness, remaining curious, and actively 
                                    feeding our curiosity through learning.
                                </p>
                            </li>
                            <li>
                                <h4>Nonviolence</h4>
                                <p>
                                    We disrupt the complacency and normalization of violence in ourselves, our community, and society at large by taking a 
                                    proactive stance against it and leading with the positive peace we wish to see in the world. Nonviolence is not only 
                                    about what we avoid, but what we do in the face of injustice.
                                </p>
                            </li>
                            <li>
                                <h4>Future Orientation</h4>
                                <p>
                                    We believe that we can transform the circumstances for animals, therefore we actively connect our personal and collective 
                                    action in the present to the future outcomes we wish to achieve.
                                </p>
                            </li>
                            <li>
                                <h4>Responsiveness</h4>
                                <p>
                                    We seek feedback from within our community and the public, and we do something about it whether criticism or compliment.
                                </p>
                            </li>
                        </ol>
                    </p>
                </div>
                <div id="shared-two">
                    <h2>II. Support and Respect Each Other</h2>
                    <p>
                        We can be in service to eachother by maintaining supportive and safe spaces, where everyone has the opportunity to reach their fullest 
                        potential and be free from discrimination.
                    </p>
                    <p>
                        <ol>
                            <li>
                                <h4>Transient Power Dynamics</h4>
                                <p>
                                    We grow deeper in relationship with each other when we influence and learn from each other as equals. We honour everyone's 
                                    individual skill sets and empower everyone to bring their skills fully by creating opportunities for everyone to lead, holding 
                                    a high level of collaboration, involving everyone in decisions that affect them, and treating everyone fairly.
                                </p>
                            </li>
                            <li>
                                <h4>Discrimination</h4>
                                <p>
                                    While discrimination of any form (human and animal) is not tolerated, we recognize that work to create safer and more accessible 
                                    spaces is ongoing. By cultivating a culture of openness, safe vulnerability, respectful discourse on sensitive topics and learning, 
                                    we hope to catalyze radical connectedness and build a bigger we.
                                </p>
                            </li>
                            <li>
                                <h4>Blaming and Shaming</h4>
                                <p>
                                    While we work to deepen our individual accountability we recognize that we live in a toxic system where interpersonal violence 
                                    happens within the context of systemic violence and that no one individual is to blame.
                                </p>
                            </li>
                            <li>
                                <h4>We are United and Autonomous</h4>
                                <p>
                                    We hold high amounts of both unity and autonomy as complementary approaches to building a strong and productive community.
                                </p>
                            </li>
                        </ol>
                    </p>
                </div>
                <div id="shared-three">
                    <h2>III. Beloved Community</h2>
                    <p>
                        We acknowledge a sanctity that exists in the consciousness of all humans and animals, and therefore strive to cultivate agape love for even 
                        those who are difficult to love. We understand that we aren't trying to win over people, but win people over. Our liberation is bound up with 
                        the liberation of all others, and therefore no one can be outside of Beloved Community.
                    </p>
                </div>
                <div id="shared-four">
                    <h2>IV. Conclusion</h2>
                    <p>
                        We aspire to build a transformative community. It's impossible to spell out every possible troubling scenario we might face, so we rely instead 
                        on one another's good judgment to uphold a high standard of integrity for ourselves and our community. We expect all those who consider themselves 
                        to be a part of our community to be guided by both the letter and the spirit of our culture. 
                    </p>
                </div>
            </div>
            <div id="explore-galleries">
                <div id="mobile-gallery-two">
                    <v-lazy
                    v-model="lazyLinksMobGalTwo"
                    :options="{
                    threshold: 1
                    }"
                    min-height="200"
                    height="fit-content"
                    transition="scale-transition"
                    class="mob-gal"
                    >
                    <MobileGallery :items="galleryOne"/>
                </v-lazy>
                </div>
                <div id="desktop-gallery">
                    <ExplorePageTitles class="section-title" title="Gallery" />
                    <div id="desk-gal-container">
                        <v-lazy
                            v-model="lazyLinksDeskGal"
                            :options="{
                            threshold: 1
                            }"
                            min-height="200"
                            height="fit-content"
                            transition="scale-transition"
                            class="desk-gal-lazy"
                            >
                            <DesktopGallery />
                        </v-lazy>
                    </div>
                </div>
            </div>
            <div id="volunteer-container">
                <ExplorePageTitles class="section-title" title="Volunteer Opportunities" />
                <h3>
                    Alongside our campaign actions, Calgary Animal Save provides sporadic and continuous volunteer opportunities in Calgary and the area including the following:
                </h3>
                <p>
                    <ul id="volunteer-ops-list">
                        <li>Responding to local issues by writing opinion articles or letters to the editor</li>
                        <li>Office work such as online research, phone calls, preparation of mailings, etc</li>
                        <li>Participating in local demonstrations and leafleting efforts</li>
                        <li>Tabling and outreach at local fairs</li>
                        <li>Meeting with or calling local representatives</li>
                        <li>Event planning; vigils, screenings, potlucks, etc</li>
                        <li>Campaign planning</li>
                        <li>Fundraising</li>
                        <li>Graphics design</li>
                        <li>Social media</li>
                    </ul>
                </p>
                <div id="volunteer-form">
                    <VolunteerForm />
                </div>
            </div>
            <div id="living-vegan-container">
                <ExplorePageTitles class="section-title" title="Living Vegan in YYC" />
                <h3>Veganism promotes the development and use of animal-free alternatives for the benefit of animals, humans and the environment. Check out some of Calgary's awesome vegan businesses and animal rescues and support local!</h3>
                <v-lazy
                    v-model="lazyLinksActive"
                    :options="{
                    threshold: 1
                    }"
                    min-height="200"
                    height="fit-content"
                    transition="scale-transition"
                    >
                    <LivingVeganLinks />
                </v-lazy>
                <p id="vol-disclaimer">Did we miss someone? <a class="anchor-color" href="#contact-us-container">Contact us</a> to have them added!</p>
            </div>
            <div id="contact-us-container">
                <ContactUsForm />
            </div>
        </div>
    </div>
</template>

<script>
import MissionStatement from '../components/MissionStatement.vue'
import ExplorePageTitles from '../components/ExplorePageTitles.vue'
import VolunteerForm from '../components/VolunteerForm.vue'
import LivingVeganLinks from '../components/LivingVeganLinks.vue'
import ContactUsForm from '../components/ContactUsForm.vue'
import MobileGallery from '../components/MobileGallery.vue'
import DesktopGallery from '../components/DesktopGallery.vue'
import Spinner from '../components/Spinner.vue'

    export default {
        name: "Explore",
        components: {
            MissionStatement,
            ExplorePageTitles,
            VolunteerForm,
            LivingVeganLinks,
            ContactUsForm,
            MobileGallery,
            DesktopGallery,
            Spinner
        },
        data() {
            return {
                pageLoaded: false,
                galleryOne: [
                    {
                        src: require('@/assets/bear-witness.jpg'),
                    },
                    {
                        src: require('@/assets/message-poster.jpg'),
                    },
                    {
                        src: require('@/assets/banner.jpg'),
                    },
                    {
                        src: require('@/assets/draw-heart.jpg'),
                    },
                    {
                        src: require('@/assets/demonstrate.jpg'),
                    },
                    {
                        src: require('@/assets/cas-team-logo.jpg'),
                    },
                    {
                        src: require('@/assets/cargill.jpg'),
                    },
                    {
                        src: require('@/assets/share-table.jpg'),
                    },
                ],
                galleryTwo: [
                    {
                        src: require('@/assets/July2021.jpg'),
                    },
                    {
                        src: require('@/assets/serve-foods.jpg'),
                    },
                    {
                        src: require('@/assets/speaker.jpg'),
                    },
                    {
                        src: require('@/assets/cowboys.jpg'),
                    },
                    {
                        src: require('@/assets/rode-platter.jpg'),
                    },
                    {
                        src: require('@/assets/juice.jpg'),
                    },
                    {
                        src: require('@/assets/supplies.jpg'),
                    },
                    {
                        src: require('@/assets/scan-me-wave.jpg'),
                    },
                ],
                lazyLinksActive: false,
                lazyLinksDeskGal: false,
                lazyLinksMobGalOne: false,
                lazyLinksMobGalTwo: false,
            }
        },
        methods: {
            onImgLoad() {
                this.pageLoaded = true;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .anchor-color {
        color:#d57f1e;
    }

    #explore-page {
        width: 100vw;
        background-color: white;
        min-height: 100vh;
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        font-family: 'Roboto', sans-serif;
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        #explore-spinner {
            display: grid;
            height: 70vh;
            width: 100%;
            justify-items: center;
            align-items: center;
        }

        #explore-header-container {
            width: 100%;

            img {
                width: 100%;
                max-height: 900px;
                object-fit: cover;
            }

            #explore-header-lrg-scrn {
                display: none;
            }
        }

        .section-title {
            text-align: center;
        }

        h2, h4, h3 {
            color: #d57f1e;
            font-family: "Helvetica";
        }

        #our-story-container {
            width: 100%;
            display: grid;
            margin-bottom: 40px;

            #image-container {
                display: grid;
                justify-items: end;
                width: fit-content;
                max-width: 480px;
                justify-self: center;
                margin-bottom: 20px;

                #henry-quote-image {
                    width: 100%;
                    justify-self: center;
                }

                p {
                    margin-right: 10px;
                }
            }

            .story-paragraph {
                margin-left: 10px;
                padding: 0 20px;
            }
        }

        #mobile-gallery-one, #mobile-gallery-two{
            width: 100%;
            height: 40vh;
            display: flex;
            justify-content: center;
            margin: 40px 0;
        }

        .mob-gal {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        #shared-principles-container {
            width: 100%;
            margin-bottom: 40px;

            #shared-one, #shared-two, #shared-three, #shared-four {
                margin-left: 10px;
                margin-top: 50px;
                padding: 0 20px;

                p > ul > li > p {
                    font-size: 1em; //required due to nesting paragraphs causing font-size em mis-match
                }
            }

            #shared-one {
                margin-top: 10px;
            }
        }

        #desktop-gallery {
            display: none;
        }

        #volunteer-container {
            width: 100%;
            margin-bottom: 40px;

            h3 {
                margin: 0 0 20px 10px;
                padding: 0 20px;
            }

            ul {
                margin-left: 30px;
                padding: 0 20px;
            }
        }

        #living-vegan-container {
            padding: 0 20px;

            h3 {
                text-align: center;
                margin-bottom: 30px;
            }

            #vol-disclaimer {
                text-align: center;
            }
        }
    }

@media screen and (min-width: 600px) {
    #explore-page {
        margin-bottom: 150px; //account for absolute footer height

        #our-story-container {

            #story-title-container {
                width: 100%;
                display: grid;

                #story-title {
                    width: fit-content;
                    justify-self: center;
                }
            }
            .story-paragraph {
                padding: 0 50px;
            }
        }

        #shared-principles-container {

            #shared-one, #shared-two, #shared-three, #shared-four {
                padding: 0 50px;
            }
        }

        #volunteer-container {

            h3 {
                padding: 0 50px;
            }

            ul {
                padding: 0 50px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #explore-page {
        max-width: 1350px;
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;

        #explore-header-container {

            img {
                max-height: 1000px;
                object-fit: cover;
            }

            #explore-header-lrg-scrn {
                display: block;
            }

            #explore-header-sml-scrn {
                display: none;
            }
        }

        #our-story-container {
            margin-top: 40px;
            display: block;
            

            #story-title {
                font-size: 1.3em;
                width: 100%;
            }

            #image-container {
                margin: 0 100px;
                float: left;

                p {
                    margin-right: 10px;
                }
            }

            #story-paragraphs {
                margin-right: 40px;
                margin-bottom: 50px;
                font-size: 0.9em;
            }
        }

        #mobile-gallery-one, #mobile-gallery-two {
            display: none;
        }

        #shared-principles-container {

            #shared-one, #shared-two, #shared-three, #shared-four {
                padding: 0 70px;
                font-size: 1em;
            }
        }

        #volunteer-container {

            h3 {
                padding: 0 70px;
                margin-right: 10vw;
            }

            ul {
                padding: 0 70px;
            }

            #volunteer-ops-list {
                display: grid;
                grid-template-columns: 50% 50%;
                font-size: 1em;

                li {
                    padding-right: 20px;
                }
            }
        }

        #desktop-gallery {
            margin-bottom: 40px;
            width: 100%;
            display: grid;
            justify-items: center;

            #desk-gal-container {
                height: 60vh;
                width: 100%;

                .desk-gal-lazy {
                    width: 100%;
                    display: grid;
                    justify-items: center;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    #explore-page {
        #our-story-container {
            #story-paragraphs {
                margin-right: 100px;
                font-size: 1em;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    #explore-page {
        #our-story-container {
            #story-paragraphs {
                font-size: 1.1em;
            }
        }

        #shared-principles-container {

            #shared-one, #shared-two, #shared-three, #shared-four {
                font-size: 1.1em;
            }
        }

        #volunteer-container {
            #volunteer-ops-list {
                font-size: 1.1em;
            }
        }

        #living-vegan-container {
            font-size: 1.1em;
        }
    }
}
</style>