<template>
    <div id="carousel-container" class="container">
        <v-carousel 
            height="60vh"
            show-arrows-on-hover 
            cycle
            >
            <v-carousel-item class="carousel-item" v-for="(item,i) in items" :key="i" eager>
                <div class="carousel-img-container">
                    <v-img class="carousel-img" :src="item.src" contain max-height="60vh" eager></v-img>
                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [
                    {
                        src: require('@/assets/July2021.jpg'),
                    },
                    {
                        src: require('@/assets/bear-witness.jpg'),
                    },
                    {
                        src: require('@/assets/message-poster.jpg'),
                    },
                    {
                        src: require('@/assets/banner.jpg'),
                    },
                    {
                        src: require('@/assets/draw-heart.jpg'),
                    },
                    {
                        src: require('@/assets/demonstrate.jpg'),
                    },
                    {
                        src: require('@/assets/cas-team-logo.jpg'),
                    },
                    {
                        src: require('@/assets/cargill.jpg'),
                    },
                    {
                        src: require('@/assets/serve-foods.jpg'),
                    },
                    {
                        src: require('@/assets/speaker.jpg'),
                    },
                    {
                        src: require('@/assets/supplies.jpg'),
                    },
                    {
                        src: require('@/assets/rode-platter.jpg'),
                    },
                    {
                        src: require('@/assets/juice.jpg'),
                    },
                    {
                        src: require('@/assets/scan-me-wave.jpg'),
                    },
                    {
                        src: require('@/assets/share-table.jpg'),
                    },
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>
    #carousel-container {
        width: 100%;
        max-width: 700px;
        z-index: 95;
        transition: 0.7s all ease-in-out;
        background: rgba(0, 0, 0, 0.877);
        border-radius: 8px;

        &:hover {
            transform: scale(1.08);
        }

        .carousel-item {
            .carousel-img-container {
                height: 100%;
                width: 100%;
                display: grid;
                justify-items: center;
                align-items: center;
                .carousel-img {
                    align-self: center;
                    height: auto;
                    max-height: 100%;
                }
            }
        }
    }
</style>