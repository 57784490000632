<template>
    <div id="carousel-container">
        <v-carousel 
            height="40vh"
            show-arrows-on-hover 
            cycle
            >
            <v-carousel-item class="mob-carousel-item" v-for="(item,i) in items" :key="i" eager>
                <div class="mob-carousel-img-container">
                    <v-img class="mob-carousel-img" :src="item.src" contain max-height="40vh" eager></v-img>
                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    export default {
        props: ["items"]
    }
</script>

<style lang="scss" scoped>
    #carousel-container {
        width: 90%;
        max-width: 700px;
        border-radius: 8px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.877);
        border-radius: 8px;

        .mob-carousel-item {

            .mob-carousel-img-container {
                height: 100%;
                width: 100%;
                display: grid;
                justify-items: center;
                align-items: center;
                .mob-carousel-img {
                    align-self: center;
                    height: auto;
                    max-height: 100%;
                }
            }
        }
    }
</style>