<template>
    <div id="volunteer-form-container" @click="hideMsg()">
        <h1>Join the team</h1>
        <v-form ref="volForm" id="volunteer-form" v-model="formValid">
            <v-text-field
                v-model="firstName"
                :rules="[nameRules.required, nameRules.min, nameRules.max]"
                filled
                color="primary"
                background-color="#ffffff"
                required
                >
                <template v-slot:label>
                    First name<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="lastName"
                :rules="[nameRules.required, nameRules.min, nameRules.max]"
                filled
                color="primary"
                background-color="#ffffff"
                required
                >
                <template v-slot:label>
                    Last name<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="email"
                :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                filled
                color="primary"
                background-color="#ffffff"
                required
                >
                <template v-slot:label>
                    Email<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="postalCode"
                :rules="[postalRules.required, postalRules.min, postalRules.max]"
                filled
                color="primary"
                background-color="#ffffff"
                required
                >
                <template v-slot:label>
                    Postal Code<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                class="vol-phone"
                v-model="phone"
                :rules="[phoneRules.max]"
                label="Phone"
                filled
                color="primary"
                background-color="#ffffff"
            ></v-text-field>
            <v-textarea
                class="vol-comments"
                v-model="comments"
                :rules="[commentsRules.max]"
                auto-grow
                rows="1"
                maxlength="150"
                counter
                label="Comments"
                filled
                color="primary"
                background-color="#ffffff"
            ></v-textarea>
            <div id="error-submit" class="vol-error">
                <h3>{{errorMsg}}!</h3>
            </div>
            <div id="success-submit">
                <h3>Thank you. We have received your information and will get in touch with you shortly.</h3>
            </div>
            <v-btn id="submit-btn"
                class="vol-submit"
                :disabled="!formValid"
                elevation="12"
                color="#e89f4c" 
                @click="sendVolunteerApp">
                    Join us!
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'

    export default {
        name: "VolunteerForm",
        data() {
            return {
                email: '',
                emailRules: {
                    required: v => !!v || 'E-mail is required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    max: v => v.length <= 50 || 'Max 50 characters'
                },
                firstName: '',
                lastName: '',
                postalCode: '',
                phone: '',
                comments: '',
                nameRules: {
                    required: value => !!value || 'Name Required.',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                postalRules: {
                    required: value => !!value || 'Postal Code Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                    max: v => v.length <= 7 || 'Max 7 characters',
                },
                phoneRules: {
                    max: v => v.length <= 20 || 'Max 20 characters',
                },
                commentsRules: {
                    max: v => v.length <= 150 || 'Max 150 characters',
                },
                formValid: false,
                errorMsg: '',
            }
        },
        methods: {
            hideMsg() {
                document.getElementById('error-submit').style.display = "none";
                document.getElementById('success-submit').style.display = "none";
            },
            sendVolunteerApp() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'tableType': 'volunteer',
                        'email': this.email,
                        'firstName': this.firstName,
                        'lastName': this.lastName,
                        'postalCode': this.postalCode,
                        'phone': this.phone,
                        'comments': this.comments,
                    }
                }).then(() => {
                    document.getElementById('success-submit').style.display = "block";
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('error-submit').style.display = "block";
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    #volunteer-form-container {
        width: 100%;
        background-color: #fcf3e957;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 30px 20px;
        margin: 80px 0;
        font-family: "Helvetica";

        h1 {
            justify-self: center;
            color: #d57f1e;
            margin-bottom: 10px;
            text-align: center;
        }

        #volunteer-form {
            display: grid;

            #error-submit {
                color: red;
                display: none;
            }

            #success-submit {
                color: green;
                display: none;
            }

            #submit-btn {
                justify-self: center;
                background-color:#d57f1e;
                padding: 25px 60px;
                margin-top: 20px;
            }
        }
    }

@media screen and (min-width: 500px) {
    #volunteer-form-container {
        
        #volunteer-form {
            display: grid;
            grid-template-columns: 48% 48%;
            gap: 0 4%;

            .vol-error {
                grid-row: 5;
                grid-column: 1 / 3;
            }

            .vol-submit {
                grid-row: 6;
                grid-column: 1 / 3;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    #volunteer-form-container {
        width: 90%;
        margin-left: 5%;
        border: 1px solid black;
        border-radius: 8px;
        padding: 30px 40px;

        h1 {
            font-size: 2.5em;
        }
    }
}

@media screen and (min-width: 1000px) {
    #volunteer-form-container {
        width: 60%;
        margin-left: 20%;
        padding: 30px 70px;
    }
}
</style>