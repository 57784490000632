<template>
    <div id="living-main-container">
        <v-expansion-panels inset class="living-panel">
            <v-expansion-panel>
                <v-expansion-panel-header class="living-exp-header">
                    Vegan Restaurants
                    <template v-slot:actions>
                        <v-icon color="#e89f4c">
                        mdi-chevron-double-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="living-content">
                        <div
                            v-for="(item,i) in restaurants"
                            :key="i"
                            class="living-veg-anchor-container"
                            >
                            - <a class="living-veg-anchor-links" target="_blank" :href="item.url">{{item.name}}</a>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="living-exp-header">
                    Vegan Fashion, Markets & Lifestyle
                    <template v-slot:actions>
                        <v-icon color="#e89f4c">
                        mdi-chevron-double-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="living-content">
                        <div
                            v-for="(item,i) in lifestyle"
                            :key="i"
                            class="living-veg-anchor-container"
                            >
                            - <a class="living-veg-anchor-links" target="_blank" :href="item.url">{{item.name}}</a>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="living-exp-header">
                    Animal Sanctuaries & Rescues
                    <template v-slot:actions>
                        <v-icon color="#e89f4c">
                        mdi-chevron-double-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="living-content">
                        <div
                            v-for="(item,i) in rescues"
                            :key="i"
                            class="living-veg-anchor-container"
                            >
                            - <a class="living-veg-anchor-links" target="_blank" :href="item.url">{{item.name}}</a>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        name: "LivingVeganLinks",
        data() {
            return {
                restaurants: [
                    {name: "The Attic Bar and Stage", url: "https://www.theatticyyc.ca/"},
                    {name: "Canela", url: "https://www.canelayyc.ca/"},
                    {name: "Copper Branch", url: "https://eatcopperbranch.com/locations/"},
                    {name: "Hearts Choices", url: "https://heartschoices.com/"},
                    {name: "kb&co", url: "https://kbandcompany.com/"},
                    {name: "Mondays Plant Cafe", url: "https://www.mondaysplantcafe.com/"},
                    {name: "Nan's Noodle House", url: "https://nansnoodlehouse.com/"},
                    {name: "Pachamama Soul", url: "https://www.pachamamasoulyyc.ca/"},
                    {name: "SaVeg Cafe", url: "https://www.savegcafe.ca/"},
                    {name: "Tamarind Vietnamese Grill and Noodle House", url: "http://www.tamarindyyc.ca/"},
                    {name: "Teff N Greens", url: "http://teffngreens.ca/"},
                    {name: "V Burger", url: "https://www.heyvburger.com/"},
                    {name: "Vegan Street Taco Bar", url: "https://veganstreet.ca/inglewood/"},
                    {name: "Happycow", url: "https://www.happycow.net/"},
                    {name: "Happy Veggie House", url: "https://www.facebook.com/happyveggiehouse/"},
                    {name: "Odd Burger", url: "https://oddburger.com/"},
                    {name: "SaVeg", url: "https://www.savegcafe.ca/"},
                    {name: "WholeLifeGo", url: "https://wholelifego.com/"},
                ],
                lifestyle: [
                    {name: "Five Dogs Gourmet Vegan Ice Cream", url: "https://www.fivedogs.ca/"},
                    {name: "Grinning Goat Fashion and Lifestyle", url: "https://www.grinninggoat.ca/"},
                    {name: "Kashew Bakery", url: "https://www.kashewbakery.com/"},
                    {name: "Minimal Llama Skincare", url: "https://minimalllama.com/"},
                    {name: "Roni's Kitchen Cooking Classes & Recipes", url: "https://roniskitchen.com/"},
                    {name: "Talk Vegan to Me Clothing", url: "https://talkvegantomeclothing.com/"},
                    {name: "Veghammer", url: "https://www.veghammer.ca/"},
                    {name: "Roll With It Baking", url: "https://www.rollwithitbaking.com/"},
                    {name: "Suzana Plant Based Foodie", url: "https://ohsuzanaohsogood.com/"},
                    {name: "The Vegan Popcorn Company", url: "https://theveganpopcorncompany.com/"},
                    {name: "Vegfest", url: "https://www.vegfestcalgary.com/"},
                    {name: "WholeLifeGo", url: "https://wholelifego.com/"},
                    {name: "Homey Food", url: "https://homeyfood.ca/"},
                    {name: "For Goodness Bake", url: "https://www.forgoodnessbake.ca/"},
                    {name: "Cheap Lazy Vegan", url: "https://www.thecheaplazyvegan.com/"},
                ],
                rescues: [
                    {name: "AARCS", url: "https://aarcs.ca/"},
                    {name: "ARTS Senior Animal Rescue", url: "https://www.animalrescuetransfersociety.com/"},
                    {name: "Almost Home Canine Rescue ", url: "https://www.almosthomeyyc.com/"},
                    {name: "The Alice Sanctuary", url: "https://www.thealicesanctuary.ca/"},
                    {name: "Calgary Animal Rescue Society", url: "https://calgaryanimalrescue.com/"},
                    {name: "Calgary Humane Society", url: "https://www.calgaryhumane.ca/"},
                    {name: "Free Spirit Sanctuary ", url: "https://www.freespiritsanctuary.ca/"},
                    {name: "Fur-Ever Homes Rescue", url: "https://www.fur-everhomesrescue.com/"},
                    {name: "Meow Foundation", url: "https://www.meowfoundation.com/"},
                    {name: "Pawsitive Match", url: "https://pawsitivematch.org/"},
                    {name: "Robins Refuge", url: "https://www.facebook.com/robinsrefuge"},
                    {name: "Rocky Mountain Animal Rescue", url: "http://www.rockymountainanimalrescue.com/"},
                    {name: "Yamnuska Wolfdog Sanctuary", url: "https://www.yamnuskawolfdogsanctuary.com/"},
                    {name: "Wildrose Rabbit Rescue", url: "https://www.facebook.com/WildRoseRabbitRescue/"},
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

    .living-veg-anchor-links {
        color: #d57f1e;
    }

    .living-main-container {
        width: 100%;
        display: grid;
        justify-items: center;
    }

    .living-veg-anchor-container {
        margin-bottom: 10px;
        width: fit-content;
    }

@media screen and (min-width: 600px) {

    .living-panel {
        width: 80%;
        margin-left: 10%;
    }

    .living-content {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .living-exp-header {
        font-size: 1.3em;
    }
}

@media screen and (min-width: 1000px) {

    .living-panel {
        width: 70%;
        margin-left: 15%;
    }

    .living-content {
        grid-template-columns: 33.3% 33.3% 33.3%;
    }

}
</style>