<template>
    <div id="contact-form-container" @click="hideMsg()">
        <h1>Contact Us</h1>
        <v-form ref="contactForm" id="contact-form" v-model="formValid">
            <div id="contact-first-name">
                <v-text-field
                    v-model="firstName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    filled
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        First name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
            </div>
            <div id="contact-last-name">
                <v-text-field
                    v-model="lastName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    filled
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Last name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
            </div>
            <div id="contact-email">
                <v-text-field
                    v-model="email"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    filled
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Email<span class="red--text"> *</span>
                    </template>
                </v-text-field>
            </div>
            <div id="contact-phone">
                <v-text-field
                    v-model="phone"
                    :rules="[phoneRules.max]"
                    filled
                    color="primary"
                    background-color="#ffffff"
                    >
                    <template v-slot:label>
                        Phone
                    </template>
                </v-text-field>
            </div>
            <div id="contact-message">
                <v-lazy>
                    <v-textarea
                    v-model="message"
                    :rules="[messageRules.required, messageRules.min, messageRules.max]"
                    no-resize
                    row="7"
                    maxlength="2000"
                    counter
                    label="Message"
                    filled
                    color="primary"
                    background-color="#ffffff"
                    >
                    <template v-slot:label>
                        Message<span class="red--text"> *</span>
                    </template>
                </v-textarea>
                </v-lazy>

            </div>
            <div id="error-contact-submit">
                <h3>{{errorMsg}}!</h3>
            </div>
            <div id="success-contact-submit">
                <h3>Thank you. We have received your message and will be in touch shortly.</h3>
            </div>
            <v-btn id="submit-contact-btn"
                :disabled="!formValid"
                color="#e89f4c"
                elevation="6" 
                @click="sendContactReq">
                    Send
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'

    export default {
        name: "ContactUsForm",
        data() {
            return {
                email: '',
                emailRules: {
                    required: v => !!v || 'E-mail is required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    max: v => v.length <= 50 || 'Max 50 characters'
                },
                firstName: '',
                lastName: '',
                message: '',
                phone: '',
                phoneRules: {
                    max: v => v.length <= 20 || 'Max 20 characters',
                },
                nameRules: {
                    required: value => !!value || 'Name Required.',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                messageRules: {
                    required: value => !!value || 'Message Required.',
                    min: v => v.length >= 10 || 'Minimum 10 characters',
                    max: v => v.length <= 2000 || 'Max 2000 characters',
                },
                formValid: false,
                errorMsg: '',
            }
        },
        methods: {
            hideMsg() {
                document.getElementById('error-contact-submit').style.display = "none";
                document.getElementById('success-contact-submit').style.display = "none";
            },
            sendContactReq() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/contact_us',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'email': this.email,
                        'firstName': this.firstName,
                        'lastName': this.lastName,
                        'phone': this.phone,
                        'message': this.message,
                    }
                }).then(() => {
                    document.getElementById('success-contact-submit').style.display = "block";
                    this.formValid = false;
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('error-contact-submit').style.display = "block";
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    #contact-form-container {
        width: 100%;
        position: relative;
        background-color: #b6b6b6;
        padding: 30px 20px;
        margin-top: 80px;
        font-family: "Helvetica";

        h1 {
            justify-self: center;
            color: white;
            margin-bottom: 10px;
            text-align: center;
            position: relative;
        }

        #contact-form {
            display: grid;
            position: relative;

            #error-contact-submit {
                color: red;
                display: none;
                justify-self: center;
                background-color: rgba(255, 255, 255, 0.76);
                padding: 5px 5px;
                border-radius: 3px;
            }

            #success-contact-submit {
                color: green;
                display: none;
                justify-self: center;
                background-color: rgba(255, 255, 255, 0.76);
                padding: 5px 5px;
                border-radius: 3px;
            }

            #submit-contact-btn {
                justify-self: center;
                background-color:#d57f1e;
                padding: 25px 60px;
                margin-top: 20px;
            }
        }
        
    }

    #contact-form-container::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('~@/assets/letters.jpg');
        background-position: center;
        background-size: cover;
        opacity: 0.3;
    }

@media screen and (min-width: 600px) {
    #contact-form-container {
        padding: 30px 40px;

        h1 {
            font-size: 2.5em;
        }
    }
}

@media screen and (min-width: 1000px) {
    #contact-form-container {
        padding: 30px 70px;

        h1 {
            font-size: 3em;
        }

        #contact-form {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 10px;

            #contact-message {
                grid-row: 3;
                grid-column: 1 / 3;
            }
            
            #error-contact-submit, #success-contact-submit {
                grid-row: 4;
                grid-column: 1 / 3;
                font-size: 1.2em;
            }

            #submit-contact-btn {
                grid-row: 5;
                grid-column: 1 / 3;
                padding: 25px 150px;
                font-size: 1.3em;
            }
        }
    }
}
</style>